
section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #384357;
    overflow: hidden;
    padding: 100px 20px;
    box-sizing: border-box;
}

section::before {
    content: 'Nike';
    color: rgba(255,255,255, 0.2);
    font-size: 480px;
    font-style: italic;
    z-index: 2;
    position: absolute;
    left: 0;
    transform: translateY(-150px);
}

section::after {
    content: '';
}

.nike-container {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.nike-container .nike-card-hover{
    background: rgba(40, 135, 187, 0.18)!important;
}

.nike-container .nike-card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 10px 25px rgba(0,0,0,0.2);
    backdrop-filter: blur(15px);
    border-radius: 10px;
}


.nike-container .nike-card .nike-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    transition: 0.5s ease-in-out;
}

.nike-container .nike-card-hover .nike-img,
.nike-container .nike-card:hover .nike-img {
    transform: translateY(-110px)!important;
}


.nike-container .nike-card .nike-img img {
    max-width: 100%;
    margin: 0 0 20px;
    transition: 0.5s ease-in-out;

}

.nike-container .nike-card-hover .nike-img img,
.nike-container .nike-card:hover .nike-img img {
    transform: translate(-20px, -40px) rotate(-25deg) scale(1.4)!important;
}


.nike-container .nike-card .nike-img h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    
}


.nike-container .nike-card .nike-content {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.nike-container .nike-card-hover .nike-content, 
.nike-container .nike-card:hover .nike-content {
    opacity: 1!important;
    visibility: visible!important;
    transform: translateY(-50px)!important;
}

.nike-container .nike-card-hover .nike-img h2,
.nike-container .nike-card:hover .nike-img h2 {
    transform: translateY(-55px)!important;
    transition: 0.5s ease-in-out!important;
}

.nike-container .nike-card .nike-content .nike-size,
.nike-container .nike-card .nike-content .nike-price
 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
}

.nike-container .nike-card .nike-content .nike-size h3,
.nike-container .nike-card .nike-content .nike-price h3 {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
}

.nike-container .nike-card .nike-content .nike-size span {
    width: 26px;
    height: 26px;
    text-align:center;
    line-height: 24px;
    display: inline-block;
    color: #111;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin: 0 5px;
    transition: 0.5s;
}

.nike-container .nike-card .nike-content .nike-price span {
    
    text-align:center;
    line-height: 24px;
    display: inline-block;
    color: #9dbc28;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin: 0 5px;
    transition: 0.5s;
}

.nike-container .nike-card .nike-content .nike-size span:hover {
    background: #9dbc28;
}

.nike-container .nike-card .nike-content button {
    position: relative;
    top: 10px;
    display: inline-block;
    padding: 12px 30px;
    background: #fff;
    border-radius: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #111;
    text-decoration: none;
    text-transform: uppercase;
}

.nike-container .nike-card .nike-content button:hover {
    position: relative;
    top: 10px;
    display: inline-block;
    padding: 12px 30px;
    background: #9dbc28;
    border-radius: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s ease-in-out;
}
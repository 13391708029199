/*@import "~antd/dist/antd.css";*/
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
:root {
  --main-color: #006174;
  --main-color-opacity:#00617477;
  --second-color:#ffcc00;

}

html, body {
  height: 100%;
  background: linear-gradient(90deg, black, var(--main-color));
}

.full-height {
  height: 100%;
}

.animation-blog {
  cursor: pointer;
  transition: all 0.2s ease 0s;
  outline: none;
}

.animation-blog:hover {
  transform: translateY(-5px);
}

.animation-blog:active {
  transform: translateY(-1px);
}

:root {
  --main-color: #006174;
  --main-color-opacity: #00617477;
  --second-color: #ffcc00;
  --third-color: rgb(8, 133, 153);
  --reserve-color: #073d6a;
}

.homem {
  background-color: white;
  height: 100%;
}

.boxm {
  display: flex;
  background-color: #ffff;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.cardm {
  display: flex;
  margin: 1rem 0 1rem 0;
  width: 40vw;
  padding: 0 0 2rem 0;
  border-radius: 1rem;
  /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #116072;
  background-color: white;
}

.cardm:hover {
  box-shadow: 5px 5px 20px #ceced078, -2px 0 3px #ffffff7e inset;
}

.cardm img {
  margin: 1rem;
}

.cardm_title {
  display: flex;
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  padding: 1rem 0 0 4rem;
  align-items: center;
}

.cardm_text {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  color: var(--third-color);
  text-align: justify;
  padding: 1.5rem 3rem 1rem 3rem;
}

.cardm_button {
  display: flex;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(90deg, black, var(--main-color));
  height: 48px;
  border-radius: 24px;
  padding: 0 2rem 0 2rem;
  justify-content: center;
  align-items: center;
}

.shadowm {
  /*box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);*/
  box-shadow: none;
}

.info {
  display: flex;
  color: var(--main-color);
  justify-content: space-between;
  /*border-bottom: var(--main-color) solid 1px;*/
  border-top: var(--main-color) solid 1px;
  padding: 0.5rem 0 0.5rem 0;
}
.info p {
  font-size: 16px;
  color: var(--main-color);
}

.head {
  display: flex;
  background: linear-gradient(90deg, black, var(--main-color));
  color: #ffff;
  font-size: 14px;
  justify-content: space-between;
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 5px;
}

.title {
  display: flex;
  text-align: center;
  justify-content: center;
  
  position: relative;
  z-index: 10;
}

.title p {
  font-weight: 600;
  font-size: 18px;
  color: var(--main-color);
}

.title .mlogo {
  background-color: white;
  transform: translateY(-10px);
  width: 120px;
  height: 120px;
  border-radius: 60px;
  box-shadow: -3px 1px 19px -6px #006174;
  display: flex;
  justify-content: center;
  align-items: center;
}

.idata {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
}

.idata p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 600;
}

.ibox {
  display: flex;
  text-align: center;
  justify-content: center;
}

.stamp_m {
  position: relative;
  display: inline-flex;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  color: red;
  padding: 15px;
  font-weight: 900;
  font-size: 18px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 6px red, inset 0px 0px 0px 9px white,
    inset 0px 0px 0px 12px red;
  transform: rotate(-30deg);
  align-items: center;
  justify-content: center;
}

.stamp_m::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url("../../assets/img/noise.jpg");

  mix-blend-mode: lighten;
}

.ibutton {
  display: flex;
  justify-content: center;
}

.pay_button {
  display: inline-flex;
  font-family: regular;
  background-color: royalblue;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 1.5rem;

  height: 3rem;
  font-size: 14px;
  font-weight: 1000;
  box-shadow: 1px 2px 4px 2px rgb(203, 191, 191);
  margin: 2rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  text-align: center;
  /*transition: 1s;*/
  /*transition: ease 0.3s;*/
}

.pay_button:hover {
  background: linear-gradient(-180deg, black, royalblue);
  color: white;
  transition: ease 0.3s;
}

.error404 {
  display: block;
  text-align: -webkit-center;
  transform: translateY(-70px);
}

.error404 p {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 900;
  transform: translateY(-40px);
}

.error404 .number {
  font-size: 72px;
  color: white;
  font-weight: 900;
  text-shadow: 4px 3px 10px var(--main-color);
  transform: translateY(-30px);
}

.error404 img {
  width: 150px;
  margin: auto;
}

.footerm {
  display: flex;
  padding: 4rem;
  background: linear-gradient(90deg, black, var(--main-color));
  color: #ffffff;
  justify-content: space-between;
  font-size: 16px;
}



.vidm {
  display: flex;
  padding: 4rem 2rem 4rem 4rem;
  justify-content: space-between;
  background-color: #d6dadb7a;
}

.vidm_desc {
  padding: 1.5rem;
  margin: 1rem 0;
  border-radius: 16px;
  box-shadow: -5px -5px 8px #ffffff7a, 5px 5px 8px #a9a9aa7a;

  flex-direction: column;
  width: 330px;
  height: 200px;
}

.vidm .prombox {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.vidm_desc div {
  padding: 0 0 1rem 0;
  font-size: 18px;
  font-weight: 900;
  color: var(--third-color);
  cursor: pointer;
}

/*.vidm_desc div:hover {
    
    padding: 0 0 2rem 0;
    font-size: 18px;
    color: var(--main-color);
    font-weight: 700;
    cursor: pointer;
    text-align: justify;
    text-align-last: justify;
}*/

.black {
  background-color: black;
}

.transparent {
  background-color: transparent;
}


.white {
  background-color: rgb(173, 217, 13);
}

.gold {
  background-color: gold;
}

.emerald {
  background-color: var(--main-color);
}
.blue {
  /*background-color: var(--reserve-color);*/
  background-color: #384357;
}

.white div {
  color: var(--reserve-color);
}

.blue div {
  color: #ffcc00;
}
.emerald div {
  color: white;
}

.gold div {
  color: black;
}

.black div {
  color: var(--second-color);
}

.upper {
    text-transform: uppercase;
}
.title {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
}
.glass {
    width: 30%;
    padding: 0 2rem 0 0;
    /*box-shadow: 5px 5px 10px rgb(25, 25, 25),
             -5px -5px 10px rgb(60, 60, 60);*/
    color: #ffffff;
    font-size: 18px;
    border-radius: 10px;
    text-align: justify;
}

.glass_m {
    color: #0e6177;
    display: inline;
}

.demo {
    width: 100vw;
    text-align: center;
    background: white;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--reserve-color);
    padding: 2rem 0 0 0;
}

.demo .title {
    font-size: 24px;
    letter-spacing: 2px;
    color: #073d6abf;
    font-style: italic;
    font-weight: 350;
    padding: 0 2rem 0.5rem 2rem;
}
@media only screen and (max-width: 834px){
    section::before {
        display: none;
    }
    .demo {
        padding: 0;
        border-radius: 1rem;
    }
    .demo .title {
        font-size: 14px;
        text-align: center;
        letter-spacing: 1px;
        color: #073d6abf;
        font-style: italic;
        font-weight: 600;
        padding: 0.5rem;
    }

    .prompt-text {
        font-size: 12px!important;
    }
}

.demo .prompt-box {
    transition: all 0.75s cubic-bezier(0.65, 0, 0.076, 1);
    display: flex;
    align-items: center;
    padding: 0 2rem .7rem 2rem;    
}
.flex-center {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.prompt-step {
    position: relative;
    display: flex;
    margin: 0;
    width: 2rem;
    height: 2rem;
    background: #282936;
    border-radius: 1rem;
    color: #fff;
    justify-content: center;
    align-items:center;
    font-weight: 600;
}

.prompt-text {
    font-size: 18px;
    font-weight: 300;
    padding: 0 1rem 0 1rem;
    text-transform: none;
    text-align: justify;
    width: 95%;
}

.prompt-text a {
    vertical-align: baseline;
}

.iphone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}
.iphone .img-frame {
    z-index: 9999;
    height: 450px;
    cursor: pointer;
}
.img-content {
    display: flex;
    position: absolute;
    border-radius: 25px;
    height: 430px;
    cursor: pointer;
    transition: ease 0.75s;
    /*content: url('https://smartpay.tj/assets/img/Telegram10.png');
    transition: ease 1s;
    animation-name: iphone_example;
    animation-duration: 25s;
    animation-delay: 5s;
    animation-iteration-count: infinite;*/
}

@keyframes iphone_example {
    0% {content: url('https://smartpay.tj/assets/img/Telegram10.png');}
    25% {content: url('https://smartpay.tj/assets/img/Telegram11.png');}
    50% {content: url('https://smartpay.tj/assets/img/Telegram12.png');}
    75% {content: url('https://smartpay.tj/assets/img/Telegram13.png');}
    100% {content: url('https://smartpay.tj/assets/img/Telegram10.png');}
}

@media print {
    .headerm {display: none;}
    .footerm {display: none;}
    
}

.invoice__title {
    font-size: 18px;
    font-weight: 600;
    color: #006D7D;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

@media only screen and (max-width: 834px){
    .footerm {
        padding: 2rem;
        font-size: 12px;
    }
}
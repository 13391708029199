.egov-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ecf0f3;
    overflow: hidden;
    padding: 2rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    font-weight: normal;
}

.egov-container .egov-box {
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border-radius: 30px;
    box-shadow: -3px -3px 7px #ffffff,
               3px 3px 5px #ceced1;

}

.egov-searchbar {
    align-self: center;
    position: absolute;
    right: 2.5rem;
}

.egov-container .egov-box .egov-title {
    margin: 0;
    color: #033e598f;
    font-style: normal;
    text-transform: capitalize;
    padding: 0 2rem;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}

.egov-container .egov-box .egov-img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.egov-container .egov-box .egov-img img {
    width: 40px;
    height: 40px;
    margin: auto;
}

.egov-filtered-img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    
}

.egov-container .egov-service {
    display: flex;
    /* justify-content: space-between; */
    padding: 2rem 0 2rem 0;
    /*align-items: center;*/
    width: 100%;
    color: #033e598f;
}

.egov-container .egov-service .egov-card {
    display: inline-block;
    text-align: left;
    width: 650px;
    /*height: 350px;*/
    background: transparent;
    font-size: 16px;
    padding: 25px;
    border-radius: 20px;
    box-shadow: -3px -3px 7px #ffffff,
               3px 3px 5px #ceced1;

}

.egov-selector {
    height: 40px;
    width: 100%;
    background: transparent;
    border-radius: 20px;
    box-shadow: -2px -4px 5px #ffffff,
        3px 1px 7px #ceced1;
    padding: 0 15px;
    margin-bottom: 1.5rem;
    -webkit-appearance:none;
}

.egov-input {
    height: 40px;
    width: 100%;
    background: #ffffff5e;
    border-radius: 20px;
    box-shadow: 3px 1px 7px #ceced1 inset, 
                -2px -4px 5px #ffffff inset;
    padding: 0 15px;
    margin-bottom: 1.5rem;
}

.egov-flex {
    display: flex;
    justify-content: space-between;
}

.egov-button {
    background: transparent;
    text-transform: uppercase;
    margin-top: 2rem;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    box-shadow: -3px -3px 7px #ffffff,
               3px 3px 5px #ceced1;
}

.item-center {
    justify-content: center!important;
}

.input-45 {
    width: 48%;
}

.egov-linebreaker {
    height: 4px;
    background: transparent;
    margin: 10px 0 10px 0;
    box-shadow: -1px -1px 1px #fff, 1px 1px 1px #ceced1;
    border-radius: 2px;
}

.egov-flex img {
    width: 40px!important;
    height: 40px!important;
    
}

.egov-flex h2 {
    margin: 10px;
    color: #033e598f;
    /*text-shadow: -1px -1px 1px #fff, 1px 1px 1px #ceced1;*/
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.egov-logo-box {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-shadow: -1px -1px 1px #fff, 1px 1px 1px #ceced1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.egov-payment {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.egov-stamp {
    position: relative;
    display: inline-flex;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    color: rgb(102, 47, 204);
    padding: 15px;
    font-weight:900;
    font-size: 18px;
    background-color: #ecf0f3;
    box-shadow: inset 0px 0px 0px 6px,
                inset 0px 0px 0px 9px #ecf0f3, 
                inset 0px 0px 0px 12px;
    transform: rotate(-30deg);
    align-items: center;
    justify-content: center;
}

.egov-stamp::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("../../assets/img/noise.jpg");
    
    
    mix-blend-mode: lighten;
}

.egov-text {
    text-transform: none;
    text-align: justify;
}


@media only screen and (max-width: 834px){
    .egov-searchbar {
        visibility: hidden;
    }
    .egov-container {
        padding: 0.75rem;
        border-radius: 0 0 1rem 1rem;
    }
    .egov-container .egov-box {
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .egov-container .egov-service .egov-card {
        margin-bottom: 1rem;
        padding: 15px;
    }
    .egov-container .egov-box .egov-img{
        width: 80px;
        height: 80px;
        border-radius: 40px;
        box-shadow: -3px -3px 7px #ffffff,
            3px 3px 5px #ceced1;
    }

    .egov-container .egov-box .egov-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
    }
    .egov-container .egov-box .egov-img img{
        width: 60px;
        height: 60px;
    }

    .egov-container .egov-service {
        display: flex;
        flex-wrap: wrap;
    }

    .egov-flex {
        display: flex;
        flex-wrap: wrap;
    }

    .egov-flex h2 { 
        display: none;
        text-align: center;
    }
    .egov-input { 
        margin-bottom: 1rem;
    }

    .egov-logo-box { 
        display: none;
    }

    .egov-button { 
        margin: 0;
    }
    .input-45 {
        width: 100%;
    }

    #card-container {
        width: 100%!important;
        box-shadow: none!important;
    }

    .receipt-container .receipt-box {
        width: 100%;
        height: 100%;
        padding-bottom: 2rem;
        margin: 0;
    }

    .receipt-container .cutted-edge {
        position:relative;
        width: auto;
        margin: 0;
    }
}
:root {
    --main-color: #006174;
    --main-color-opacity:#00617477;
    --second-color:#ffcc00;

}

@keyframes moving {
    0% {
      transform:translateX(100vw)
    }
    50% {
      transform: translateX(50vw)
    }
    100% {
      transform: translateX(0)
    }
  }

.main_container {
    background: linear-gradient(90deg, black, var(--main-color) );
    display: block;
    width: 100vw;
    height: 100%;
    
}


.h_searchbar {
    align-self: center;
    position: absolute;
    right: 8rem;
}

.headerm {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    /*position: sticky;
    top: 0;
    z-index: 999;*/
    background: linear-gradient(90deg, black, var(--main-color) );
}



.flagm {
    clip-path: circle(1.3rem at center);   
}

.flag_bar {
    display: flex;
    width: 100vw;
    position: absolute;
    justify-content: space-around;
    transition: all 0.2s;
    animation: moving 0.2s linear;
    top: 1.8rem;
    
    
}

.flag_bar_item {
    display: flex;
    margin: 0;
    align-items: center;
    clip-path: circle(1.3rem at center);
    cursor: pointer;
}

.flag_bar_item:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.logom {
    height: 6rem;
    margin: 0.2rem 1rem 0.2rem 1rem;
}

.main_container p {
    color: #ffffff;
}

@media screen and (max-width:375px) {
    .logo {
        height: 3rem;
    }
    .flag {
        clip-path: circle(1rem at center);
    }

    .flag_bar_item {
        clip-path: circle(1rem at center);
    }

    .flag_bar {
        top: 1rem;
        
    }
    
}
.container {
    display:block;
    margin: -6rem 20rem 5rem 20rem;
    /*box-shadow: 4px 4px 10px 1px #b9d6e0;*/
    background-color: aliceblue;
    border-radius: 10px !important;
    
}

.container_m {
    /*display:block;
    height: 100vh;
    display:inline;*/
    background: #fff;
    border-radius: 10px;
}

.pay_button {
    display: flex;
    background: linear-gradient(90deg, black, var(--main-color));
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 1.5rem;
    padding: 20px;
    height: 3rem;
    width: inherit;
    font-size: 18px;
    box-shadow: 1px 2px 4px 2px rgb(203, 191, 191);
    margin: 2rem;
    /*transition: 1s;*/
    transition: ease 1s;

}

.pay_button:hover {
    
    background-color: #4199af;
    color: #ffffff;
    transition: ease 1s;
}

.subheader {
    display: flex;
    height: 6rem;
    background-color: transparent;
    color: aliceblue;
    
    
}

.invoiceheader {
    display: flex;
    /*padding: 10px 4rem 10px 4rem;*/
    background-color: #fff;
    border-radius: 10px 10px 0 0;
}

.invoiceheader img {
    height: 100%;
}

.invoiceheader .div__text {
    align-items: center;
    justify-content: center;
    /*padding: 0 0.5rem 0 1rem;*/
    color: #397f90;
    width:100% 
    /*color: #093541;*/
    
}
.invoiceheader .div__text_m {
    /*width: 80vw;*/
    padding: 0;
    color: #397f90; 
    /*color: #093541;*/
    
}



.invoiceheader .div__box {
    display: flex;
    width: 100%;
}

.invoiceheader .div__box_m {
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    width: 100vw;
    align-items: center;
    
}
.invoiceheader .div__box_m2 {
    display: flex;
    padding: 1rem 1rem 1rem 2rem;
    width: 100vw;
    align-items: center;
    background: linear-gradient(90deg, black, var(--main-color));
}
.invoiceheader .div__img {
    
    
    margin: auto;
    padding:0;
    justify-content: center;
    color: #397f90;  
    display: flex; 
}

.invoiceheader span {
    
    display:block;
    font-size: 16px;
}

.notes {
    
    font-size: 14px !important;
    font-weight: 200;
}



.linebreaker {
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, transparent, #397f90);
}

.linebreaker2 {
    height: 2px;
    width: 100%;
    background: linear-gradient(-90deg, transparent, #397f90);
}

.pricebreaker {
    height: 1px;
    margin: 0rem 3.5rem 0rem 3.5rem;
    background-color: #627377bf;
}
.pricebreaker_m {
    height: 1px;
    margin: 0;
    background-color: #627377bf;
}

.invoicedata {
    display: flex;
    padding: 0.2rem 4rem 0.2rem 4rem;
    width: 100%;

}

.invoicedata_m {
    color: #627377;
    display: flex;
    padding: 0.5rem 1rem;
    width: 100vw;
    align-items: center;

}

.invoicedata_m .col1 { text-align: left; width: 50%;}

.col1 { text-align: justify; width: 50vw; padding-right: 0.5rem;}
.col2 { text-align: center; width: 10vw; padding-right: 0.5rem;}
.col3 { text-align: center; width: 10vw;}
.col4 { text-align:right; width: 15vw;}
.col5 { text-align: right; width: 15vw;}

.invoicedata > div {
    color: #627377;
}

.invoicesummary {
    display: flex;
    padding: 0.5rem 4rem 0rem 4rem;
}

.invoicesummary_m {
    background: linear-gradient(90deg, black, var(--main-color));
    color: #ffffff;
    display: flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100vw;
}

.payedsummary {
    color: #627377;
    display: flex;
    padding: 0rem 4rem 1rem 4rem;
    
}

.payedsummary_m {
    background: linear-gradient(90deg, black, var(--main-color));
    color: #ffffff;
    display: flex;
    padding: 0rem 1rem 0.5rem 1rem;
    width: 100vw;
}

.payedsummary_m .foot1, .invoicesummary_m .foot1 { width: 80vw; text-align: right;}
.payedsummary_m .foot2, .invoicesummary_m .foot2 { width: 20vw; text-align: right;}


.foot1 { width: 85%; text-align: right;}
.foot2 { text-align: right; width: 15%;}

.invoicesummary > div {
    color: #627377;
}




.payment {
    display: flex;
    background-color: #ffffff;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: -moz-available;
}

.stripe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-available;
    margin: 2rem 2rem 2rem 4rem!important;
}

.stripe_m {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-available;
    margin: 2rem 0rem 2rem 0rem;
}

.payment_m {
    
    display: inline;
    /*flex-wrap: wrap;*/
    justify-content: center;
    
}

@media (orientation: landscape) {
    .payment_m {
    
        display: flex;
        /*flex-wrap: wrap;*/
        justify-content: center;
        align-items: center;
        width: -moz-available;
        
    }

    .stripe_m {
        display: flex;
        justify-content: center;
        align-items: center;
        width: -moz-available;
        margin: 2rem 0rem 2rem 0rem;
    }
    
  }

.qrcode {
    display: flex; /*temporary disabled*/
    margin: 2rem 0 2rem 4rem;
    padding: 0;
    width: -moz-available;
}

.qrcode_m {
    display: none; /*temporary disabled*/
    justify-content: center;
    align-items: center;
    width: -moz-available;
}

.stamp {
    border: solid 4px rgb(146, 9, 9);
    padding: 0.5rem 2rem 0.5rem 2rem;
    color: rgb(146, 9, 9);
    font-weight: bolder;
    font-size: 24px;
    position: absolute;
    top:0;

    transform: rotate(-30deg) translateY(17rem) 
}

.stamp_m {
    border: solid 4px rgb(146, 9, 9);
    padding: 0.5rem 2rem 0.5rem 2rem;
    color: rgb(146, 9, 9);
    font-weight: bolder;
    font-size: 24px;
    position: absolute;
    top:0;

    transform: translateY(35vh) translateX(30vw) rotate(-30deg)  
}

.invoicefooter {
    display: flex;
    height: 5rem;
    padding: 10px 20px 10px 20px;
    text-align: justify;
    color:#006D7D;
    font-size: 16px;
    font-weight: 400;
    border-radius:  0 0 10px 10px;
}

@media print {
    html, body * {
        visibility: hidden;
      }
      .container, .container * {
        visibility: visible;
      }
      .container {
        transform: scale(1.3);
        position: absolute;
        width: 210mm;
        margin: 0;
        left: 10rem;
        top: 5rem;
      }

    .invoicefooter, .pay_button, .qrcode {
        display: none;
      }
    
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-input-box {
  font-size: 12px;
  font-family: arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: #202124;
  display: flex;
  z-index: 3;
  height: 44px;
  background: white;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  
  /*max-width: 224px;*/
}

.card-input {
  font-size: 12px;
  font-family: arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: #202124;
  display: flex;
  z-index: 3;
  height: 40px;
  background: white;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
}


.card-input-spacer {
  display: flex;
  padding: 8px;
  margin: 2px;
  color: transparent;
  white-space: pre;
  height: 34px;
  font-size: 12px;
  width: 10%;
}

.card-input-cardholder {
  opacity: 0.6;
}

.card-input::placeholder {
  color: #bfbfc6;
}



.card-input:focus, .card-input:focus .card-input-box {
  outline: none;
}

.receipt-container {
 
  background: transparent;
  display: inline-block;
}
.receipt-container .receipt-box {
  transition: 0.5s ease-in-out;
  width: 400px;
  height: 420px;
  display: inline-block;
  justify-content: center;
  background: #fff;
  margin-top: 4px;
}
.receipt-container .cutted-edge {
  content: '';
  width: 400px;
  position: absolute;
  background: transparent;
  border-top: #fff dotted 4px;

}

.receipt-container img {
  width: 60px;
  height: 80px;
  margin: auto;
  filter: grayscale(100%);
}
.receipt-linebreaker {
  height: 1px;
  background: #232323c8;
  margin: 0 20px 0 20px;
}
.receipt-title {
  font-size: 14px;
  font-weight: 600;
  color: #232323c8;
}

.receipt-text {
  font-size: 12px;
  font-weight: 300;
  color: #232323c8;
}
.receipt-item-box {
  display: flex;
  justify-content: space-between;
  margin: 5px 20px 5px 20px;
  text-transform: none!important;

}

.left_80 {
  width: 80%;
  text-align: left;
}
.right {
  text-align: right;
}

.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.receipt-qr-box {
  display: flex;
  justify-content: center;
}

.cafe-container {
    display: flex;
    justify-content: center;
    background: #384357;
    overflow: hidden;
    padding: 2rem;
    box-sizing: border-box;
    font-size: 14px;
    text-transform: none;
    color: rgba(238, 238, 238, 0.7);
    font-weight: normal;
    font-family: Chalkboard SE;
}

.cafe-container .cafe-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;

}
.cafe-container .cafe-terminal-frame {
    background: black;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 6px #ceced1 inset, 
        -2px -4px 5px #110f0f inset;    
    flex-wrap: wrap;
    width: 50%;
    margin-left: 2rem;
    border-radius: 20px;
}

.cafe-container .cafe-terminal-frame .cafe-terminal {
    background: #fff;
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 80%;
    color: #232323;
    font-family: 'Courier New', Courier, monospace;
    margin: 2rem 2rem 0rem 2rem;
    border-radius: 13px;
    padding: 5px;
    z-index: 10;
    font-size: 1.2vw;
}

.cafe-container .cafe-terminal-frame .cafe-terminal-logo {
    width: 150px;
    height: 50px;
    padding: 10px;
}

.blackboard {
    position: relative;
    width: 640px;
    border: tan solid 12px;
    border-top: #bda27e solid 12px;
    border-left: #b19876 solid 12px;
    border-bottom: #c9ad86 solid 12px;
    box-shadow: 0px 0px 6px 5px rgba(58, 18, 13, 0), 0px 0px 0px 2px #c2a782, 0px 0px 0px 4px #a58e6f, 3px 4px 8px 5px rgba(0, 0, 0, 0.5);
    background-image: radial-gradient( circle at left 30%, rgba(34, 34, 34, 0.3), rgba(34, 34, 34, 0.3) 80px, rgba(34, 34, 34, 0.5) 100px, rgba(51, 51, 51, 0.5) 160px, rgba(51, 51, 51, 0.5)), linear-gradient( 215deg, transparent, transparent 100px, #222 260px, #222 320px, transparent), radial-gradient( circle at right, #111, rgba(51, 51, 51, 1));
    background-color: #333;
}

.blackboard:before {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient( 175deg, transparent, transparent 40px, rgba(120, 120, 120, 0.1) 100px, rgba(120, 120, 120, 0.1) 110px, transparent 220px, transparent), linear-gradient( 200deg, transparent 80%, rgba(50, 50, 50, 0.3)), radial-gradient( ellipse at right bottom, transparent, transparent 200px, rgba(80, 80, 80, 0.1) 260px, rgba(80, 80, 80, 0.1) 320px, transparent 400px, transparent);
    border: #2c2c2c solid 2px;
    content: "Меню";
    font-family: 'Chalkboard SE';
    font-size: 2.2em;
    color: rgba(238, 238, 238, 0.7);
    text-align: center;
}


.cafe-container .cafe-menu .cafe-item {
    display: inline-block;
    width: 50%;
    height: auto;
    padding: 5px;

}

.cafe-container .cafe-menu .cafe-item-img {
    display: flex;
    width: 200px;
    height: 200px;
    text-align: center;
    justify-content: center;
    margin: auto;
    align-items: center;
    flex-wrap: wrap;
}

.cafe-container .cafe-menu .cafe-item-img img {
    margin: 0;
    width: 150px;
    height: 150px;
    object-fit: contain;
    cursor: pointer;
    z-index: 10;
}

.cafe-container .cafe-menu .cafe-item-img img:hover {
    transform: scale(1.2);
    transition: 0.5s ease-in-out;
}

.cafe-container .cafe-menu .cafe-item-img h2 {
    transform: translateY(25px);
    font-size: 16px;
}

.cafe-container .cafe-menu .cafe-item-content {
    display: inline-block;
}

.cafe-container .cafe-terminal .button-box {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.cafe-container .cafe-terminal .red {
    background: #c01010;
}

.cafe-container .cafe-terminal .green {
    background: #10c01f;
}

.cafe-container .cafe-terminal button {
    position: relative;
    top: 10px;
    display: inline-block;
    padding: 8px 30px;
    /*border: rgb(148, 147, 147) solid 2px;*/
    box-shadow: -2px -4px 5px #ffffff,
        3px 1px 7px #969697;
    border-radius: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;
    color: rgba(238, 238, 238, 1);
    font-family:Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}

.cafe-container .cafe-menu .cafe-item-content .card-price {
	display: inline-block;
  
  width: auto;
	height: 38px;
	
	background-color: #6ab070;
	-webkit-border-radius: 3px 4px 4px 3px;
	-moz-border-radius: 3px 4px 4px 3px;
	border-radius: 3px 4px 4px 3px;
	
	border-left: 1px solid #6ab070;

	/* This makes room for the triangle */
	margin-left: 19px;
	
	position: relative;
	
	color: white;
	font-weight: 300;
	font-size: 14px;
	line-height: 38px;

	padding: 0 10px 0 10px;
    transform: rotate(60deg) translate(-30px,-130px);
}

/* Makes the triangle */
.cafe-container .cafe-menu .cafe-item-content .card-price:before {
	content: "";
	position: absolute;
	display: block;
	left: -19px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 19px solid transparent;
	border-right: 19px solid #6ab070;
}

/* Makes the circle */
.cafe-container .cafe-menu .cafe-item-content .card-price:after {
	content: "";
	background-color: #333;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	display: block;
	position: absolute;
	left: -9px;
	top: 17px;
}

.cafe-terminal-merchant-logo {
    width: 50px;
    height: 50px;
    margin: 10px auto 10px auto;
}
.cafe-terminal-orders-box {
    display: inline-flex;
    width: 100%;
    font-size: 1vw;
    padding: 0 1.5rem 0 1.5rem;
    /* align-items: center; */
}
.cafe-terminal-orders-box .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
}

.cafe-terminal-orders-box .flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.cafe-terminal .total {
    display: flex;
    justify-content: space-between;
}

.cafe-terminal .center {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.cafe-terminal .center-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cafe-terminal .center-center img {
    margin: 0;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.cafe-terminal .center-block {
    display: inline-block;
    text-align: center;
}

.cafe-terminal .payment-text {
    font-size: 16px;
    font-weight: 600;
}

.cafe-terminal-orders-box .order-name {
    
    text-align: left;
    padding-left: 1rem;
    
}

.cafe-terminal-orders-box .order-price {

    text-align: right;
    width: 35%;
}

@media only screen and (max-width: 834px){
    .cafe-container {
        flex-wrap: wrap;
        padding: 0;
        margin: 0.2rem;
        background: none;
        
    }
    .cafe-container .cafe-menu { 
        width: 100%;
        
    }
    .cafe-container .cafe-menu .cafe-item-content .card-price {
        transform: rotate(60deg) translate(-25px,-65px);
    }

    .cafe-container .cafe-menu .cafe-item-img h2 {
        transform: translateY(30px);
        z-index: 9;
    }

    .cafe-container .cafe-terminal-frame {
        width: 100%;
        margin: 2rem 0.5rem 2rem 0.5rem;
    }
    .cafe-container .cafe-terminal-frame .cafe-terminal {
        height: 500px;
        margin: 1rem 1rem 0rem 1rem;
        border-radius: 5px;
        font-size: 15px;
    }

    .cafe-container .cafe-menu .cafe-item-img {
        width: 150px;
        height: 150px;
    }

    .cafe-container .cafe-menu .cafe-item-img img {    
        width: 120px;
        height: 150px;
        transform: translateX(-25px);
    }
    .cafe-terminal-orders-box {
        font-size: 13px;
    }  
}
:root {
    --main-color: #006174;
    --main-color-opacity:#00617477;
    --second-color:#ffcc00;

}



.home {
    
    background-color: white;
    border-radius: 1rem;
    height: 100%;
    padding: 2rem;
    text-align: center;
    
}

.box {
    display: flex;
    background-color: #ffff;
    height: 250px;
    flex-wrap: wrap;
    
}
.card-container {
    position: relative;
    width: 100%;

}
.card {
    display: flex;
    position: absolute;
    margin: 1rem 0 1rem 0;
    font-size: 22px;
    font-weight: 800;
    padding: 1rem;
    height: 220px;
    border-radius: 1rem;
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
    /*transition: all 1.3s cubic-bezier(.25,.8,.25,1);*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    color: #116072;
    background-color: white;
    transition: ease 0.75s;
}

.card:hover {
    box-shadow: 0 0 0 1px #adacac7a;
    
    
    
    /*box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);*/
    /*transform: scale(1.2);*/
    /*border-bottom: 2px solid rgba(0,0,0,0.1);
    border-right: 2px solid rgba(0,0,0,0.4);*/
}

.card img {
    margin: 1rem;
}

.shadow {
    /*box-shadow: 4px 4px 8px #ffffff7a inset,
                0 0 0 1px #adacac7a,
                -2px -3px 8px #a9a9aa7a inset;*/
              
    box-shadow: -5px -5px 8px #ffffff7a, 
        5px 5px 8px #a9a9aa7a;
   }


.invoice {
    background-color: #ffff;
    border-radius: 2rem;
    height: 100%;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}

.icontent {
    width: 100%;
    transform: translateY(-30px);
}
.info {
    display: flex;
    color: var(--main-color);
    justify-content: space-between;
    /*border-bottom: var(--main-color) solid 1px;*/
    border-top: var(--main-color) solid 1px;
    padding: 0.5rem 0 0.5rem 0;  
}
.info p {
    font-size: 16px;
    color: var(--main-color);
    
}

.head {
    display: flex;
    background: linear-gradient(90deg, black, var(--main-color));
    color: #ffff;
    font-size: 14px;
    justify-content: space-between;
    padding: 0.3rem 1rem 0.3rem 1rem;
    border-radius: 5px;
}

.title {
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.title p {
    font-weight: 600;
    font-size: 18px;
    color: var(--main-color);
}

.title .mlogo {
    background-color: white;
    transform: translateY(-10px);
    width: 120px;
    height: 120px;
    border-radius: 60px;
    box-shadow: -3px 1px 19px -6px #006174;
    display: flex;
    justify-content: center;
    align-items: center;

}

.idata {
    display: flex;
    margin: 1rem;
    justify-content: space-between;
}

.idata p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 600;

}

.ibox {
    display: flex;
    text-align: justify;
    color: #006174;
    justify-content: center;
    margin: 10px;
}

.stamp_m {
    position: relative;
    display: inline-flex;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    color: red;
    padding: 15px;
    font-weight:900;
    font-size: 18px;
    background-color: white;
    box-shadow: inset 0px 0px 0px 6px red,
                inset 0px 0px 0px 9px white, 
                inset 0px 0px 0px 12px red;
    transform: rotate(-30deg);
    align-items: center;
    justify-content: center;
}

.stamp_m::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("../../assets/img/noise.jpg");
    
    
    mix-blend-mode: lighten;
}


.ibutton {
    display: flex;
    justify-content: center;
}

.pay_button {
    display: inline-flex;
    font-family: regular;
    background-color: royalblue;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 1.5rem;
    
    height: 3rem;
    font-size: 14px;
    font-weight: 1000;
    box-shadow: 1px 2px 4px 2px rgb(203, 191, 191);
    margin: 2rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    text-align: center;
    /*transition: 1s;*/
    /*transition: ease 0.3s;*/

}

.pay_button:hover {
    
    background: linear-gradient(-180deg, black, royalblue);
    color: white;
    transition: ease 0.3s;
}

.error404 {
    display: block;
    text-align: -webkit-center;
    transform: translateY(-70px);
    
}

.error404 p {
    color: var(--main-color);
    font-size: 24px;
    font-weight: 900;
    transform: translateY(-40px);
}

.error404 .number {
    font-size: 72px;
    color: white;
    font-weight: 900;
    text-shadow: 4px 3px 10px var(--main-color);
    transform: translateY(-30px);
}

.error404 img {
    width: 150px;
    margin: auto;
}

.merchant {
    display: inline-block;
    width: 100%;
    color: var(--main-color);
}

.merchant .mtitle {
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.merchant .addr {
    font-weight: 400;
    text-align: center;
}

.mreceipt-info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-top: 1px solid var(--main-color);
    color: #116072;
    padding: 0 10px 20px 10px;   
}

.mreceipt-info div {
    font-size: 14px;
    font-weight: 500;
    color: #116072;   
}

.mreceipt-data {
    display: inline-block;
    width: 100%;
    padding: 10px;
    color: #006174;
}

.mreceipt-data div {
    display: flex;
    justify-content: space-between;
}

.solutions-m {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.solutions-m h2 {
    font-size: 20px;
    font-weight: 600;
    color: #0e6177;
}

.solutions-m a {
    font-size: 16px;
    text-decoration: none;
    color: #0e6177;
    padding: 0.5rem;
}


